import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { ChevronLeft, ChevronRight } from 'lucide-react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


export const CarouselContentParser = ({ content }) => {
  // 匹配所有图片链接
  const imageRegex = /!\[.*?\]\((.*?)\)/g;
  const images = [];
  let match;

  while ((match = imageRegex.exec(content)) !== null) {
    images.push(match[1]); // 提取图片 URL
  }

  // 如果没有图片，则返回 null 或者其他内容
  if (images.length === 0) {
    return 
     (<div> </div>);
  }

  return (
    <div>
      <ImageCarousel images={images} /> 
    </div>
  );
};


const ImageCarousel = ({ images }) => {
  if (!images || images.length === 0) {
    return <p>No images available</p>;
  }

  return (
    <div className="max-w-2xl mx-auto relative"> {/* Add max width and center */}
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={1}
        navigation={{
          prevEl: '.custom-swiper-button-prev',
          nextEl: '.custom-swiper-button-next',
        }}
        pagination={{ clickable: true }}
        className="rounded-lg overflow-hidden"
      >
        {images.map((src, index) => (
          <SwiperSlide key={index} className="flex items-center justify-center">
            <div className="flex justify-center">
              <img 
                src={src} 
                alt={`Slide ${index + 1}`} 
                className="w-auto h-auto max-w-full max-h-64 object-contain" 
                loading="lazy"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* Custom navigation buttons */}
      <button className="custom-swiper-button-prev absolute left-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 text-white p-2 rounded-r z-10">
        <ChevronLeft className="w-6 h-6" />
      </button>
      <button className="custom-swiper-button-next absolute right-0 top-1/2 transform -translate-y-1/2 bg-black bg-opacity-30 hover:bg-opacity-50 text-white p-2 rounded-l z-10">
        <ChevronRight className="w-6 h-6" />
      </button>
    </div>
  );
};

export default ImageCarousel;