import React, { useState, useRef } from 'react'
import { ChevronLeft, ChevronRight } from 'lucide-react'

// Sample image array - replace with your actual image paths
const importAll = (r: __WebpackModuleApi.RequireContext) => r.keys().map(r);
const images = importAll(require.context('../images', false, /\.(jpg|jpeg|png)$/));

export default function PhotoSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef(0); // To store the initial touch position
  const touchEndX = useRef(0); // To store the final touch position

  // Handlers for next and previous image
  const goToNextImage = () => {
    setCurrentIndex((prev) => (prev + 1) % images.length);
  }

  const goToPreviousImage = () => {
    setCurrentIndex((prev) => (prev - 1 + images.length) % images.length);
  }

  const getPreviousIndex = () => (currentIndex - 1 + images.length) % images.length;
  const getNextIndex = () => (currentIndex + 1) % images.length;

  // Handle touch start event
  const handleTouchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX; // Store the initial touch position
  };

  // Handle touch end event
  const handleTouchEnd = (e: React.TouchEvent) => {
    touchEndX.current = e.changedTouches[0].clientX; // Store the final touch position

    // Calculate the swipe direction
    const swipeDistance = touchStartX.current - touchEndX.current;

    // If swipe distance is large enough to consider it a swipe
    if (Math.abs(swipeDistance) > 50) {
      if (swipeDistance > 0) {
        // Swipe left, go to next image
        goToNextImage();
      } else {
        // Swipe right, go to previous image
        goToPreviousImage();
      }
    }
  };

  // Handle click on left image (previous image)
  const handleClickPreviousImage = () => {
    goToPreviousImage();
  };

  // Handle click on right image (next image)
  const handleClickNextImage = () => {
    goToNextImage();
  };

  return (
    <div
      className="relative w-full h-[500px] overflow-hidden"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <div className="absolute inset-0 flex justify-center items-center">
        <div className="relative flex items-center justify-center w-full h-full">
          {/* Previous Image */}
          <img
            src={images[getPreviousIndex()]}
            alt={`Previous image`}
            className="absolute left-0 w-1/4 h-auto max-h-[400px] object-cover opacity-50 blur-sm cursor-pointer"
            onClick={handleClickPreviousImage} // Click on left image to go previous
          />
          {/* Current Image */}
          <img
            src={images[currentIndex]}
            alt={`Current image`}
            className="w-1/2 h-auto max-h-[500px] object-contain z-10"
          />
          {/* Next Image */}
          <img
            src={images[getNextIndex()]}
            alt={`Next image`}
            className="absolute right-0 w-1/4 h-auto max-h-[400px] object-cover opacity-50 blur-sm cursor-pointer"
            onClick={handleClickNextImage} // Click on right image to go next
          />
        </div>
      </div>

      {/* Left and Right Buttons */}
      <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-4 z-20">
        <button
          className="rounded-full bg-white bg-opacity-50 hover:bg-opacity-75 transition-colors duration-200 p-2"
          onClick={goToPreviousImage}
        >
          <ChevronLeft className="h-6 w-6" />
        </button>
        <button
          className="rounded-full bg-white bg-opacity-50 hover:bg-opacity-75 transition-colors duration-200 p-2"
          onClick={goToNextImage}
        >
          <ChevronRight className="h-6 w-6" />
        </button>
      </div>
    </div>
  );
}
