import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Heart, MessageCircle  } from 'lucide-react';
import { format } from 'date-fns';
import ReactDOM from "react-dom";
import VditorPreview from "vditor/dist/method.min";
import { useTheme } from './components/ThemeContext';
import ThemeSwitcher from './components/ThemeSwitcher';
import { CarouselContentParser } from './components/ImageCarousel';
import { Edit } from 'lucide-react';
import OptimizedComments from './components/OptimizedComments'


const organizeCommentsIntoTree = (comments) => {
  const commentMap = new Map();
  const rootComments = [];

  // First pass: Create map of all comments
  comments.forEach(comment => {
    commentMap.set(comment.id, {
      ...comment,
      replies: []
    });
  });

  // Second pass: Organize into tree structure
  comments.forEach(comment => {
    const commentWithReplies = commentMap.get(comment.id);
    if (comment.commentId === comment.pageId) {
      // This is a root level comment
      rootComments.push(commentWithReplies);
    } else {
      // This is a reply
      const parentComment = commentMap.get(comment.commentId);
      if (parentComment) {
        parentComment.replies.push(commentWithReplies);
      }
    }
  });

  return rootComments;
};

// Comment component that handles both comments and replies
const Comment = ({ comment, pageId, onReply, theme, level = 0 }) => {
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState('');

  const handleReplySubmit = () => {
    onReply(replyText, comment.id);
    setReplyText('');
    setShowReplyInput(false);
  };

  return (
    <div 
      className={`rounded-lg p-4 mb-4 ${
        theme === 'dark' ? 'bg-black' : 'bg-gray-100'
      }`}
      style={{ marginLeft: `${level * 20}px` }}
    >
      <p className={`font-bold ${
        theme === 'dark' ? 'text-white' : 'text-black'
      }`}>
        {comment.userName} (ID: {comment.userId})
      </p>
      <p className="text-gray-500 text-sm">
        {format(new Date(comment.commentTime), 'yyyy-MM-dd HH:mm:ss')}
      </p>
      <p className={`${
        theme === 'dark' ? 'text-white' : 'text-black'
      }`}>
        {comment.commentText}
      </p>
      
      <button
        onClick={() => setShowReplyInput(!showReplyInput)}
        className="text-blue-500 hover:text-blue-700 mt-2 text-sm"
      >
        回复
      </button>

      {showReplyInput && (
        <div className="mt-2 flex flex-row items-center">
          <button
            onClick={handleReplySubmit}
            className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-1 rounded-lg shadow-md transition duration-300 ease-in-out mr-3"
            type="button"
          >
            回复
          </button>
          <input
            type="text"
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleReplySubmit();
              }
            }}
            className={`border rounded-lg px-4 py-1 w-full ${
              theme === 'dark' ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'
            } shadow-md focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out`}
            placeholder="写下你的回复..."
          />
        </div>
      )}

      {/* Render replies */}
      {comment.replies && comment.replies.map(reply => (
        <Comment
          key={reply.commentId}
          comment={reply}
          pageId={pageId}
          onReply={onReply}
          theme={theme}
          level={level + 1}
        />
      ))}
    </div>
  );
};


function PostDetail() {
  const { id } = useParams();
  // const { post, likeStatus, likeCount, comments, isLoading, error } = useFetchPost(id);
  const { theme } = useTheme();
  const color1 = (theme === 'dark') ? '300' : '900';
  const [isOutlineVisible, setIsOutlineVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const markdownRef = useRef(null);
  const userId = localStorage.getItem('userId');
  const [post, setPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [likeStatus, setLikeStatus] = useState(null);
  const [likeCount, setLikeCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [likeMessage, setLikeMessage] = useState('');
  const [commentsTree, setCommentsTree] = useState([]);

const handleLike = async () => {
    if (!userId) {
    setLikeMessage('未登录用户无法评论和点赞。'); // 设置提示信息
    setTimeout(() => {
      setLikeMessage(''); // 一段时间后清除提示信息
    }, 3000); // 3秒后清除
    return; // 终止执行
  }
  const newLikeStatus = likeStatus === 1 ? 2 : 1; // 切换状态
  setLikeStatus(newLikeStatus); // 立即更新状态以改变样式

  try {
    const response = await fetch('https://chat.hamburgerhan.com/posts/like', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userKey: localStorage.getItem('apiKey'),
        likeOperate: newLikeStatus,
        pageId: id,
        likeId: id,
      }),
    });
    
    // 假设返回中包含新的点赞数量
    setLikeCount(likeCount+(newLikeStatus === 1 ? 1 : -1)); // 更新点赞数量
  } catch (error) {
    console.error('Error liking post:', error);
    // 如果出错，可以将状态重置为之前的状态
    setLikeStatus(likeStatus === 1 ? 2 : 1); // 反转回去
  }
};



const handleComment = async (text, parentCommentId = null) => {
  if (!userId) {
    setLikeMessage('未登录用户无法评论和点赞。');
    setTimeout(() => {
      setLikeMessage('');
    }, 3000);
    return;
  }
  
  if (!text || !text.trim()) return; // 确保文本不为空

  const commentText = text;
  const commentId = parentCommentId || id; // Use parent comment ID or page ID

  try {
    const response = await fetch('https://chat.hamburgerhan.com/posts/comment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userKey: localStorage.getItem('apiKey'),
        pageId: id,
        commentId: commentId,
        commentText,
        commentType: 'PAGE',
        commentEndIndex: 0,
        commentStartIndex: 0,
      }),
    });

    const newCommentId = await response.text(); // Get the new comment ID
    
    // Create new comment object with the correct structure
    const newComment = {
      id: newCommentId, // The new comment's ID
      pageId: id,
      commentId: commentId, // This is the parent ID
      userId: userId,
      userName: localStorage.getItem('userName'),
      userAvatarUrl: localStorage.getItem('avatar'),
      commentText: commentText,
      commentTime: new Date().toISOString(),
      replies: []
    };

    // Update the comments state with the new comment
    setComments(prevComments => {
      const updatedComments = [...prevComments, newComment];
      const newTree = organizeCommentsIntoTree(updatedComments);
      setCommentsTree(newTree);
      return updatedComments;
    });
    
    // Clear input and hide it if it's a top-level comment
    if (!parentCommentId) {
      setCommentText('');
      setShowCommentInput(false);
    }
  } catch (error) {
    console.error('Error adding comment:', error);
  }
};


  useEffect(() => {
    const fetchPost = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`https://chat.hamburgerhan.com/posts/${id}?userId=${userId}`);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const data = await response.json();
        setPost(data);
        setLikeStatus(data.likeStatus);
        setLikeCount(data.likes);
        const commentsData = data.commentVOList || [];
        setComments(commentsData);
        const newTree = organizeCommentsIntoTree(commentsData);
        setCommentsTree(newTree);
      } catch (e) {
        setError('Failed to fetch post. Please try again later.');
        console.error('Fetch error:', e);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPost();
  }, [id, userId]);

    useEffect(() => {
    const commentTree = organizeCommentsIntoTree(comments);
    setCommentsTree(commentTree);
  }, [comments]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (markdownRef.current) {
      const outlineElement = document.getElementById("outline");
      VditorPreview.preview(markdownRef.current, post?.content, {
        speech: {  
          enable: true,
        },
        theme: { current: theme },
        hljs: { style: theme === 'dark' ? 'github-dark' : 'github' },
        mode: theme === 'dark' ? 'dark' : 'light',
        anchor: 1,
        lazyLoadImage: 'https://hamburgerhan.com/loading.svg',
        after: () => {
          setTimeout(() => {
            if (markdownRef.current) {
              const customTags = markdownRef.current.querySelectorAll(".carousel");
              customTags.forEach((tag) => {
                const content = tag.textContent || "";
                if (tag.classList.contains("processed")) return;
                const parentNode = tag.parentNode;
                if (parentNode) {
                  const container = document.createElement("div");
                  tag.classList.add("processed");
                  parentNode.replaceChild(container, tag);
                  ReactDOM.render(
                    <CarouselContentParser content={content} />,
                    container
                  );
                }
              });
            }
          }, 3000);
          if (outlineElement && !isMobile) {
            outlineElement.style.backgroundColor = theme === 'dark' ? '#1e1e1e' : '#ffffff';
            outlineElement.style.color = theme === 'dark' ? '#d4d4d4' : '#333333';
            VditorPreview.outlineRender(
              document.getElementById("viewDoc"),
              outlineElement
            );
          }
        }
      });
    }
  }, [post?.content, theme, isMobile]);

  if (isLoading) return <div className={`page ${theme} w-full`}><p className="text-center text-gray-500 mt-8">加载中...</p></div>;
  if (error) return <p className="text-center text-red-500 mt-8">{error}</p>;
  if (!post) return <p className="text-center text-gray-500 mt-8">No post found.</p>;

  return (
    <div className={`page ${theme} w-full min-h-screen`}>
      <nav className={`bg-${theme} shadow-md w-full`}>
        <div className="w-full px-4">
          <div className="flex items-center justify-between h-16">
            <Link to="/" className="flex items-center text-gray-600 hover:text-red-500">
              <ArrowLeft className="h-6 w-6 mr-2" />
              返回
            </Link>
            <div className="flex flex-col items-center text-center mb-4">
              <h2 className={`text-xl md:text-2xl font-bold text-gray-${color1} mb-2`}>{post.title}</h2>
              <p className="text-sm text-gray-500">
                {format(new Date(post.createdAt), 'yyyy年MM月dd日 HH:mm')}
              </p>
                          {/* 编辑按钮 */}
            {userId === '00000' && (
              <Link to={`/editMarkdown/${id}`} className="flex items-center text-blue-500 hover:text-blue-700 mb-4">
                <Edit className="h-5 w-5 mr-1" />
                <span>编辑</span>
              </Link>
            )}
            </div>
            <ThemeSwitcher />
          </div>
        </div>
      </nav>
            {/* 提示信息 */}
    {likeMessage && (
      <div className="fixed top-0 left-1/2 transform -translate-x-1/2 bg-red-500 text-white text-center py-2 px-4 z-50">
        {likeMessage}
      </div>
    )}

 <main className="w-full px-4 py-6">
        <article className={`bg-${theme} rounded-lg shadow-md overflow-hidden w-full flex flex-col md:flex-row`}>
          <div className="flex w-full">
            {!isMobile && (
              <div
                id="outline"
                style={{
                  display: isOutlineVisible ? "block" : "none",
                  width: "13%",
                  maxWidth: "300px",
                  paddingRight: "1rem",
                  borderRight: "1px solid #555",
                  position: "sticky",
                  top: "1rem",
                  backgroundColor: theme === 'dark' ? '#1e1e1e' : '#ffffff',
                  zIndex: 10,
                  transition: "all 0.3s ease",
                }}
              >
                目录
              </div>
            )}
            <div
              id="viewDoc"
              ref={markdownRef}
              className="flex-1 w-full overflow-x-auto"
              style={{
                paddingLeft: isMobile ? "0" : "1rem",
                overflowY: "auto",
                maxHeight: "calc(100vh - 100px)",
              }}
            >
              {/* 文章内容 */}
            </div>
          </div>
        </article>

        {/* 将评论和点赞部分移到article之外 */}
        <div className="w-full mt-4">
          {post && (
  <div className="flex items-center justify-between mb-4">
    <div className="flex items-center space-x-4">
      <button
        className={`flex items-center ${
          likeStatus === 1 ? 'text-red-500' : 'text-gray-' + color1
        } hover:text-red-500`}
        onClick={handleLike}
      >
        <Heart className="h-6 w-6 mr-2" />
        <span>{likeCount}</span>
      </button>

      <button
        variant="ghost"
        size="sm"
        className="flex items-center text-gray-500 hover:text-gray-700"
        onClick={() => setShowCommentInput(!showCommentInput)}
      >
        <MessageCircle className="h-6 w-6 mr-2" />
        <span>{comments.length}</span>
      </button>
    </div>
  </div>
)}
        
{/* 添加评论输入框 */} 
{showCommentInput && (  
<div className="w-full mb-4 flex flex-row items-center sm:flex-row">
  <button
    onClick={() => handleComment(commentText)}
    className="bg-blue-600 hover:bg-blue-700 text-white px-3 py-2 rounded-lg shadow-md transition duration-300 ease-in-out mr-3"
    type="button"
    style={{ height: '2.5rem', lineHeight: '1.5rem' }} // 修改高度和行高
  >
    评
  </button>

  {/* 输入框样式 */}
  <input
    type="text"
    value={commentText}
    onChange={(e) => setCommentText(e.target.value)}
    onKeyPress={(e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleComment(commentText);
      }
    }}
    className={`border rounded-lg px-3 py-2 w-full sm:w-auto ${
      theme === 'dark' ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-800 border-gray-300'
    } shadow-md focus:outline-none focus:border-blue-500 transition duration-300 ease-in-out`}
    placeholder="添加评论..."
    style={{ height: '2.5rem', lineHeight: '1.5rem' }} // 设置高度和行高
  />

</div> 
)}  



          {/* 评论列表 */}
    <OptimizedComments comments={commentsTree} onReply={handleComment} theme={theme} />
        </div>
      </main>

      <footer className={`bg-${theme} border-t border-gray-200 mt-8 w-full`}>
        <div className="w-full py-6 px-4">
          <p className="text-center text-gray-500 text-sm">
            © 2024 Hamburger. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}

export default PostDetail;