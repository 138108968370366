import React, { createContext, useState, useEffect, useContext } from 'react';  
  
const ThemeContext = createContext();  
  
export const ThemeProvider = ({ children }) => {  
  const [theme, setTheme] = useState(() => {  
    const savedTheme = localStorage.getItem('theme');  
    return savedTheme || 'light';  
  });  
  
  useEffect(() => {  
    localStorage.setItem('theme', theme);  
  }, [theme]);  
  
  const toggleTheme = () => {  
    setTheme(prevTheme => prevTheme === 'light' ? 'dark' : 'light');
  };  
  
  return (  
    <ThemeContext.Provider value={{ theme, toggleTheme }}>  
      {children}  
    </ThemeContext.Provider>  
  );  
};  
  
export const useTheme = () => useContext(ThemeContext);