import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useTheme } from './ThemeContext';

Modal.setAppElement('#root'); // 设置你的应用根元素

const UserInfoModal = ({ isOpen, onClose }) => {
  const { theme } = useTheme();
  const [user, setUser] = useState({
    name: '',
    email: '',
    userId: '',
    avatar: '',
  });

  useEffect(() => {
    if (isOpen) {
      // 当模态框打开时从 localStorage 中获取用户信息
      const name = localStorage.getItem('userName');
      const email = localStorage.getItem('email');
      const userId = localStorage.getItem('userId');
      const avatar = localStorage.getItem('avatarUrl');
      
      // 更新状态
      setUser({
        name: name || '未知',
        email: email || '未知',
        userId: userId || '未知',
        avatar: avatar || '',
      });
    }
  }, [isOpen]); // 每当模态框打开时重新获取数据

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '400px',  // 设置宽度
      maxWidth: '90%',  // 最大宽度
      padding: '20px',  // 内边距
      borderRadius: '8px', // 边框圆角
      boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)', // 阴影效果
      backgroundColor: theme === 'dark' ? '#333' : '#fff', // 根据主题设置背景颜色
      color: theme === 'dark' ? '#fff' : '#000', // 根据主题设置文字颜色
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // 半透明背景
    },
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles} ariaHideApp={false}>
      <h2 className="text-xl font-bold mb-4">用户信息</h2>
      <div className="flex items-center mb-4">
        <img src={user.avatar} alt="User Avatar" className="h-16 w-16 rounded-full mr-4" />
        <div>
          <p><strong>名称：</strong>{user.name}</p>
          <p><strong>邮箱：</strong>{user.email}</p>
          <p><strong>用户 ID：</strong>{user.userId}</p>
        </div>
      </div>
      <button onClick={onClose} className="bg-blue-500 text-white px-4 py-2 rounded">关闭</button>
    </Modal>
  );
};

export default UserInfoModal;
